import { BigNumber, ethers } from 'ethers';
import { useContext } from 'react';
import { Context } from '../Store';
import popsABI from '../contractABIs/popsABI.json';
import usdcABI from '../contractABIs/usdcABI.json';


declare const window:any;
let provider:any = undefined;
let popsContract:any = undefined;
let usdcContract:any = undefined;
const FANTOM_NETWORK_ID = "250";

//Provider and Initialization
export const initializeEthers = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const addr = await signer.getAddress();

    if (window.ethereum.networkVersion === FANTOM_NETWORK_ID) {
      dispatch({type:"onFantomNetwork", content:true});
    }
    dispatch({type: 'walletContextDetected', content: true });
    dispatch({type: 'triggerAll', content: false});

    popsContract = new ethers.Contract(
      "0xC9b205c2dA5f83eD5919cf90be9458a5A55897d9",
      popsABI,
      signer
    );

    usdcContract = new ethers.Contract(
      "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      usdcABI,
      signer
    )

    console.log(popsContract);

    return addr;
  } catch (error) {
    console.log(error);
    dispatch({type: 'walletContextDetected', content: false });
    dispatch({type:"onFantomNetwork", content: false});
    return undefined;
  }
}

export const checkNetwork = () => {
  try {
    return (window.ethereum.networkVersion === FANTOM_NETWORK_ID);
  } catch {
    return false;
  }
}

export const checkTotalSupply = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const supplyOfPops = await popsContract.totalSupply();
    //console.log(supplyOfPops.toNumber());
    dispatch({type: 'totalPopsSupply', content: supplyOfPops.toNumber()});
  } catch(error) {
    return console.log(error);
  }
}

export const checkForWhitelistMint = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    let isWhitelisted = false;
    const signer = provider.getSigner(0);
    const userAddress = signer.getAddress();
    const whitelistNumber = await popsContract.whitelistedAddresses(userAddress);
    console.log(whitelistNumber.toNumber());
    if(whitelistNumber.toNumber() === 1) {
      isWhitelisted = true;
    }
    dispatch({type: 'isWhitelisted', content: isWhitelisted});
    return isWhitelisted;
  } catch(error) {
    return console.log(error);
  }

}

export const mint = async (dispatch:any, amountToMint:any) => {
  try {
    const ftmAddress = '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75'
    const zeroAddress = '0x0000000000000000000000000000000000000000'
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    try {
      const connectedpopsContract = await popsContract.connect(signer);
      const tx = await connectedpopsContract.mint(ftmAddress, amountToMint, zeroAddress,
        {gasLimit: 280000, "value": 0}
      );
      await tx.wait();
    } catch(error) {
      return console.log(error);
    }
  } catch(error) {
    return console.log(error);
  }
}

export const approveUSDC = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const connectedusdcContract = await usdcContract.connect(signer);
    let tx = await connectedusdcContract.approve("0xC9b205c2dA5f83eD5919cf90be9458a5A55897d9", ethers.constants.MaxUint256);
    await tx.wait();
    const allowance = connectedusdcContract.allowance(signer.getAddress(), "0xC9b205c2dA5f83eD5919cf90be9458a5A55897d9");
    if(allowance > 0) {
      dispatch({type: 'isUSDCApproved', content: true})
    }
    else {
      throw console.error("USDC Not Approved Properly");
      ;
    }
  } catch(error) {
    console.log(error);
  }
}

export const checkForIsApproved = async (dispatch:any) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const connectedusdcContract = await usdcContract.connect(signer);
    const allowance = await connectedusdcContract.allowance(signer.getAddress(), "0xC9b205c2dA5f83eD5919cf90be9458a5A55897d9");
    console.log("Allowance: ", allowance);
    if(allowance > 0) {
      dispatch({type: 'isUSDCApproved', content: true})
    }
  } catch(error) {
    console.log(error);
  }
}