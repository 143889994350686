import React, { useContext, useEffect, useState } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import discord_img from "./images/discord.png";
import twitter_img from "./images/twitter.png";
import surv from "./images/surv.png";
import survlogo from "./images/surv-logo.png";
import station from "./images/station.png";
import menu_img from "./images/menu.png";
import gallery from "./images/gallery.png";
import nftkey from "./images/nftkey.png";
import surveyor from "./images/8.png";
import mace from "./images/mace.png";
import funeral from "./images/funeral.png";
import buns from "./images/buns.png";
import logo from "./images/flag.png";
import mm_logo from "./images/metamask-logo.png";
import examples from "./images/nft-examples.png";

import { mint, checkTotalSupply, initializeEthers, checkForWhitelistMint, approveUSDC, checkForIsApproved } from "./functions/ethersFunctions";
import ConnectWallet from "./components/ConnectWallet";
import { Context } from "./Store";

const menuToggle = () => {
    const navlinks = document.querySelector('.nav-links');
    navlinks?.classList.toggle('mobile-menu');
}
function App() {

  //const [active, setActive] = React.useState("Public");
  const [state, dispatch]:any = useContext(Context);
  const [spendInput, setSpendInput] = useState('');

  useEffect(() => {
    async function getInit() {
        await initializeEthers(dispatch);
    }
    getInit();
  },[]);

  useEffect(() => {
    async function getSupply() {
        await checkTotalSupply(dispatch);
        await checkForWhitelistMint(dispatch);
        await checkForIsApproved(dispatch);
    }
    getSupply();
  },[state.walletAddress]);

  return (
    <Router>
    <div className="App">
           <nav className="navbar">
                <h1 className="logo"><a>SURVEYORS</a></h1>
                <ul className="nav-links">
                    <li><a href="https://surveyor-dao.gitbook.io/surveyor-dao/">Docs</a></li>
                    <li><Link to="/"><ConnectWallet/></Link></li>
                </ul>
                <img src={menu_img} alt="" className="menu-btn" onClick={menuToggle}/>
            </nav>
            <header>
            <div>
            <section className="drops">
            <div className="title">
            <h1>SURVEYORS</h1>
            <h2>{state.totalPopsSupply}/1,000</h2>
            </div>
            <div className="row">
                <div className="col">
                <div className="mint-text">
                    <h4>Take the test and become a Surveyor</h4>
                    {
                            !state.walletAddress ? <h3>Connect your wallet to summon</h3> : 
                            state.walletAddress && state.isWhitelisted ? 
                            <>
                                <h4>You are on the list. Your Surveyor will cost $125 USDC.</h4>
                                <br/>
                                {
                                    !state.isUSDCApproved ?
                                    <button onClick={() => approveUSDC(dispatch)} disabled={false}>Approve USDC</button> :
                                    <button onClick={() => mint(dispatch, 1)}  disabled={false}>Summon</button>
                                }
                            </>
                            :
                            <>
                            {
                                !state.isUSDCApproved ?
                                <button onClick={() => approveUSDC(dispatch)} disabled={false}>Approve USDC</button> :
                                <button onClick={() => mint(dispatch, 1)}  disabled={false}>Summon</button>
                            }
                            </>
                    }
                    <h4>Public mint price is 250 $USDC</h4>
                </div>
                </div>
                <div className="col">
                </div>
            </div>

            <img className="pop-skully"src={surv} alt=""/>
            </section>
            </div>
            </header>
            <section className="info">
                <h1>Surveyor DAO</h1>
                <h4>A group of seasoned investors coming together to share insight and tips. Your NFT will give you exclusive access to alpha, market opportunities and strategies. The Surveyor DAO was founded by our fearless leader, Shitcoin Surveyor, on his quest to harvest the highest yeilds across the land.</h4>
                <h3>Expanding to AVAX in Q3</h3>
            </section>


            <section className="surv">
            <div className="surv-content">

                <div className="surv-logo">
                <img src={survlogo}/>
                </div>
                <div className="surv-info">
                    <div className="surv-links">
                    <ul>
                    <li><a href="https://surveyor-dao.gitbook.io/surveyor-dao/">Read the Docs</a></li>
                    </ul>
                    </div>
                </div>
    
            </div>
            </section>
            <section className="collection">

                


            </section>
            <section className="roadmap">
            
            <div className="roadmap-content">
            
            <h1>Roadmap</h1>
            <div className="row">
            

                <div className="roadmap-cards">
                <div className="card">  
                <h2>Honorary Tributes</h2>
                <p>Custom NFTs for heavy hitters in the Fantom community</p>
                </div>
                <div className="card">  
                <h2>Multi-Chain NFT Community</h2>
                <p>Releasing Surveyor NFTs to serve as your PFP and access pass to the DAO</p>
                </div>
                <div className="card">  
                <h2>Alpha Channels</h2>
                <p>Exclusive market insight for DAO members</p>
                </div>
                <div className="card">  
                <h2>Airdrop</h2>
                <p>NFT holders will receive an allocation of $SURV based on rarity</p>
                </div>
                <div className="card">  
                <h2>DAO</h2>
                <p>Implementing governance by introducing proposals</p>
                </div>

                </div>
            </div>
            </div>
            </section>
            <section className="wallets">
            <div className="wallet-cards">
            <div className="row">
                <div className="col">
                    <h1>Are you new to Web3?</h1>
                    <p>You can use a wallet like Metamask to safely store your FTM and other crypto currencies. This wallet will be installed as a browser extension and serve as your account as you explore new projects.</p>

                </div>
                <div className="card">
                    <img src={mm_logo} alt=""/>
                    
                        <p>Metamask is the most popular software wallet and browser extension. Metamask allows you to store mainnet FTM and interact with Fantom dApps.</p>
  
                    <div className='card-links'>
                    <ul>
                    <li><a href="https://metamask.io">Download Metamask </a></li>
                    <li><a href="https://docs.fantom.foundation/tutorials/set-up-metamask">Set up Metamask on Fantom</a></li>
                    </ul>
                    </div>
                </div>
            </div>
            </div>
            </section>
            <section className="team">
            <div className="team-members">
            <h1>The Team</h1>
            <div className="row">
                <div className="col">
                <a href="https://twitter.com/CoinSurveyor"><img className="team-member" src={surveyor} alt=""/></a>
                <h3>Surveyor</h3>
                <h4>Founder</h4>
                </div>
                <div className="col">
                <a href="https://twitter.com/yolofinancial"><img className="team-member" src={funeral} alt=""/></a>
                <h3>Funeral</h3>
                <h4>Artist</h4>
                </div>
                <div className="col">
                <a href="https://twitter.com/CryptoMacePapa"><img className="team-member" src={mace} alt=""/></a>
                <h3>Mace Papa</h3>
                <h4>Dev</h4>
                </div>
                                <div className="col">
                <a href="https://twitter.com/0xBuns"><img className="team-member" src={buns} alt=""/></a>
                <h3>Buns</h3>
                <h4>Dev</h4>
                </div>
            </div>
            </div>
            </section>
            <section className="card-section">
            <a href="https://discord.gg/q8ZcWD5h68"><div className="join">
                <h1>Join our discord</h1>
                <Switch>
                    <Route exact path="/">
                    <div className="card">
                    <img src={logo} alt=""/>
                    <div className="bottom-card">
                    <button>Enter</button>
                    </div>
                    </div>
                    </Route>
                </Switch>
                <p>The next printooor awaits</p>
            </div></a>
            </section>
            <section className="footer">
                <div className="social-links">
                    <a href="https://twitter.com/SurveyorDAO"><img src={twitter_img} alt=""/></a>
                    <a href="https://discord.gg/q8ZcWD5h68"><img src={discord_img} alt=""/></a>
                    <a href="https://nftkey.app/collections/"><img src={nftkey} alt=""/></a>
                    <a href="https://fantomgallery.io"><img src={gallery} alt=""/></a>
                  </div>
            </section>
        </div>
        </Router>
    );
}

export default App;
